.space {
  padding: 3% 0;
}

.resources-intro-text {
  padding-bottom: 40px;
}

.resources-intro-text p {
  font-style: italic;
}

.resources-statement {
  height: 100%;
}