.card-image-overlay {
  filter: brightness(90%);
}

.btn {
  margin: 2% 0;
  background-color: var(--button) !important;
}

.event-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
