@import url(https://fonts.googleapis.com/css2?family=Cormorant+Infant&family=Cormorant+Unicase:wght@500&family=Red+Hat+Display:ital@1&display=swap&family=Red+Hat+Text&family=Cormorant);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Quicksand:wght@600&display=swap);
/* Old Font */

/* Font pairing 2*/

.App {
  text-align: center;
  margin: 0;
  padding: 0;
}

:root {
  --description: #49494d;
  --header: #df6747;
  --accent-one: #e3e3cd;
  --accent-two: #878d92;
  --button: #b42f32;
  --header-font: "Nunito", sans-serif;
  --content-font: "Quicksand", sans-serif;

  --font-color: var(--description);
  --alt-font-color: var(--accent-one);
}

* {
  color: #49494d;
  color: var(--font-color);
}

html,
body,
p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.required {
  color: red;
}

.carousel {
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
  font-family: var(--header-font);
  font-style: normal;
}

p {
  font-size: 16px;
}

.timeline-link {
  align-items: center;
  text-align: center;
  color: #000000;
  position: relative;
  top: -50px;
}

/* Resource Panel */
.resource-panel h1 {
  font-family: Cormorant Unicase;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #976969;
}

.resource-panel .gray-resources button,
.resource-panel .red-resources button {
  font-family: Red Hat Text;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
  opacity: 0.9;
}

.resource-panel .gray-resources button {
  background: rgba(105, 105, 105, 0.3);
  opacity: 0.9;
}

.resource-panel .red-resources button {
  background: rgba(105, 0, 0, 0.36);
  opacity: 0.9;
}

.form-header {
  font-family: Cormorant;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  align-items: center;
  text-align: center;
}

/* Color for forms */
.input-field input:focus + label {
  color: #e53935 !important;
}
/* label underline focus color */
.row .input-field input:focus {
  border-bottom: 1px solid #e53935 !important;
  box-shadow: 0 1px 0 0 #e53935 !important;
}

/* About Page */
.about-header h1 {
  font-family: Cormorant Unicase;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 78px;
  /* identical to box height */
  letter-spacing: 0.15em;
  mix-blend-mode: normal;
}

.about-header p {
  font-style: italic;
  font-weight: normal;
  font-size: 15.5px;
  line-height: 24px;
  text-align: center;
  
}

.picture-board h2 {
  font-family: Cormorant Unicase;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #976969;
}

.pictures {
  display: flex;
  justify-content: space-evenly;
}

.pictures p {
  font-family: Red Hat Text;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.2em;
  color: #000000;
  padding: 0 5%;
}

.slides img {
  -webkit-filter: blur(5px) brightness(60%);
          filter: blur(5px) brightness(60%);
}

.space-horizontal {
  padding: 0 2%;
}

.card-image-overlay {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.btn {
  margin: 2% 0;
  background-color: var(--button) !important;
}

.event-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page {
  padding: 0;
}

.section {
  max-width: 1000px;
  width: 90%;
}

/* Home Intro */

.home-intro {
  background: linear-gradient(rgba(73, 73, 77, 0.75), rgba(73, 73, 77, 0.75)),
    url("https://tnttcttpictures.s3-us-west-2.amazonaws.com/src/pages/home/Cover.jpeg");
  height: 700px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-intro-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.home-title {
  margin: 0;
  padding: 20px 0 10px;
  /* Identical to box height */
  color: var(--alt-font-color);
  letter-spacing: 0.1em;
  mix-blend-mode: normal;
}

.home-subtitle {
  margin: 0;
  padding: 10px 0 20px;
  font-family: var(--content-font);
  font-style: italic;
  text-align: center;
  color: var(--alt-font-color);
}

.resource-polygon {
  opacity: 0.5;
  transition: opacity 0.1;
}

.resource-polygon:hover {
  opacity: 1;
}

.resource-text {
  font-size: larger;
  font-family: Red Hat Text;
}

.hex-grid {
  margin: 50px 0;
}

.hex-grid h4 {
  color: var(--alt-font-color) !important;
}

.hex-button {
  display: inline-block;
  position: relative;
  height: 110px;
  width: 190px;
  margin: 36px 10px;
}

.hex {
  overflow: hidden;
  transform: translate(-50%, -50%) rotate(120deg);
  width: 190px;
  height: 380px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.inner-hex {
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: rotate(-60deg);
}

.hex-content {
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

nav,
.sidenav {
  background-color: var(--header) !important;
}

nav ul .dropdown-trigger {
  padding: 0 calc(0.5vw + 4px);
}

nav ul a.btn {
  background-color: var(--button) !important;
  color: var(--accent-one) !important;
}

nav ul a.btn,
.dropdown-trigger, .sidenav li>a, .about {
  font-family: var(--header-font);
}

.dropdown-trigger,
.sidenav li > a,
nav i.material-icons, .about {
  color: var(--accent-one) !important;
}

.dropdown-content li > a,
.dropdown-content li > span,
.sidenav .dropdown-content li > a {
  font-family: var(--content-font);
  color: var(--header) !important;
}

.time-info {
  background: var(--accent-one);
  padding-top: 20px;
}

.time-info p {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}

.time-info,
.copyright-bar {
  font-family: var(--header-font);
}

.time-info a,
.time-info p {
  color: var(--description);
}

.time-info .social-media a {
  margin: 0 5% 0 0;
}

.copyright-bar {
  background-color: var(--header);
  text-transform: uppercase;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  margin: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright-bar p {
  color: var(--accent-one) !important;
}

.space {
  padding: 3% 0;
}

.resources-intro-text {
  padding-bottom: 40px;
}

.resources-intro-text p {
  font-style: italic;
}

.resources-statement {
  height: 100%;
}
