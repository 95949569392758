nav,
.sidenav {
  background-color: var(--header) !important;
}

nav ul .dropdown-trigger {
  padding: 0 calc(0.5vw + 4px);
}

nav ul a.btn {
  background-color: var(--button) !important;
  color: var(--accent-one) !important;
}

nav ul a.btn,
.dropdown-trigger, .sidenav li>a, .about {
  font-family: var(--header-font);
}

.dropdown-trigger,
.sidenav li > a,
nav i.material-icons, .about {
  color: var(--accent-one) !important;
}

.dropdown-content li > a,
.dropdown-content li > span,
.sidenav .dropdown-content li > a {
  font-family: var(--content-font);
  color: var(--header) !important;
}
