.time-info {
  background: var(--accent-one);
  padding-top: 20px;
}

.time-info p {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}

.time-info,
.copyright-bar {
  font-family: var(--header-font);
}

.time-info a,
.time-info p {
  color: var(--description);
}

.time-info .social-media a {
  margin: 0 5% 0 0;
}

.copyright-bar {
  background-color: var(--header);
  text-transform: uppercase;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  margin: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright-bar p {
  color: var(--accent-one) !important;
}
