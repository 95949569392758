/* Old Font */
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Infant&family=Cormorant+Unicase:wght@500&family=Red+Hat+Display:ital@1&display=swap&family=Red+Hat+Text&family=Cormorant");

/* Font pairing 2*/
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Quicksand:wght@600&display=swap");

.App {
  text-align: center;
  margin: 0;
  padding: 0;
}

:root {
  --description: #49494d;
  --header: #df6747;
  --accent-one: #e3e3cd;
  --accent-two: #878d92;
  --button: #b42f32;
  --header-font: "Nunito", sans-serif;
  --content-font: "Quicksand", sans-serif;

  --font-color: var(--description);
  --alt-font-color: var(--accent-one);
}

* {
  color: var(--font-color);
}

html,
body,
p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.required {
  color: red;
}

.carousel {
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--header-font);
  font-style: normal;
}

p {
  font-size: 16px;
}

.timeline-link {
  align-items: center;
  text-align: center;
  color: #000000;
  position: relative;
  top: -50px;
}

/* Resource Panel */
.resource-panel h1 {
  font-family: Cormorant Unicase;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #976969;
}

.resource-panel .gray-resources button,
.resource-panel .red-resources button {
  font-family: Red Hat Text;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
  opacity: 0.9;
}

.resource-panel .gray-resources button {
  background: rgba(105, 105, 105, 0.3);
  opacity: 0.9;
}

.resource-panel .red-resources button {
  background: rgba(105, 0, 0, 0.36);
  opacity: 0.9;
}

.form-header {
  font-family: Cormorant;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  align-items: center;
  text-align: center;
}

/* Color for forms */
.input-field input:focus + label {
  color: #e53935 !important;
}
/* label underline focus color */
.row .input-field input:focus {
  border-bottom: 1px solid #e53935 !important;
  box-shadow: 0 1px 0 0 #e53935 !important;
}

/* About Page */
.about-header h1 {
  font-family: Cormorant Unicase;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 78px;
  /* identical to box height */
  letter-spacing: 0.15em;
  mix-blend-mode: normal;
}

.about-header p {
  font-style: italic;
  font-weight: normal;
  font-size: 15.5px;
  line-height: 24px;
  text-align: center;
  
}

.picture-board h2 {
  font-family: Cormorant Unicase;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #976969;
}

.pictures {
  display: flex;
  justify-content: space-evenly;
}

.pictures p {
  font-family: Red Hat Text;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.2em;
  color: #000000;
  padding: 0 5%;
}

.slides img {
  filter: blur(5px) brightness(60%);
}

.space-horizontal {
  padding: 0 2%;
}
