.home-page {
  padding: 0;
}

.section {
  max-width: 1000px;
  width: 90%;
}

/* Home Intro */

.home-intro {
  background: linear-gradient(rgba(73, 73, 77, 0.75), rgba(73, 73, 77, 0.75)),
    url("https://tnttcttpictures.s3-us-west-2.amazonaws.com/src/pages/home/Cover.jpeg");
  height: 700px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-intro-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.home-title {
  margin: 0;
  padding: 20px 0 10px;
  /* Identical to box height */
  color: var(--alt-font-color);
  letter-spacing: 0.1em;
  mix-blend-mode: normal;
}

.home-subtitle {
  margin: 0;
  padding: 10px 0 20px;
  font-family: var(--content-font);
  font-style: italic;
  text-align: center;
  color: var(--alt-font-color);
}

.resource-polygon {
  opacity: 0.5;
  transition: opacity 0.1;
}

.resource-polygon:hover {
  opacity: 1;
}

.resource-text {
  font-size: larger;
  font-family: Red Hat Text;
}

.hex-grid {
  margin: 50px 0;
}

.hex-grid h4 {
  color: var(--alt-font-color) !important;
}

.hex-button {
  display: inline-block;
  position: relative;
  height: 110px;
  width: 190px;
  margin: 36px 10px;
}

.hex {
  overflow: hidden;
  -webkit-transform: translate(-50%, -50%) rotate(120deg);
  -moz-transform: translate(-50%, -50%) rotate(120deg);
  -ms-transform: translate(-50%, -50%) rotate(120deg);
  -o-transform: translate(-50%, -50%) rotate(120deg);
  transform: translate(-50%, -50%) rotate(120deg);
  width: 190px;
  height: 380px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.inner-hex {
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

.hex-content {
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
